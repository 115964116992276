import React from 'react';
import './Contact.scss';
import Button from '../Button/Button';

export default function Contact() {
    const [name, setName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [subject, setSubject] = React.useState('');
    const [message, setMessage] = React.useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Construction de l'objet de données à envoyer
        const payload = {
            name,
            lastName,
            email,
            subject: subject || 'Webdesign',
            message
        };

        try {
            const response = await fetch('https://corentinrenard.com/api/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                window.alert('Votre message a bien été envoyé !');
                // Réinitialiser le formulaire
                setName('');
                setLastName('');
                setEmail('');
                setSubject('');
                setMessage('');
            } else {
                // Afficher l'erreur reçue du serveur
                const error = await response.text();
                window.alert(`Erreur lors de l'envoi du message : ${error}`);
            }
        } catch (error) {
            window.alert(`Erreur de connexion : ${error.message}`);
        }
    };

    return (
        <div className="contact">
            <h1 className="title">Me contacter</h1>
            <form onSubmit={handleSubmit}>
                <div className="input-field">
                    <input
                        required
                        type="text"
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                    />
                    <label>Prénom</label>
                </div>
                <div className="input-field">
                    <input
                        required
                        type="text"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                    <label>Nom</label>
                </div>
                <div className="input-field">
                    <input
                        required
                        type="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    <label>Adresse email</label>
                </div>
                <select
                    value={subject}
                    onChange={(event) => setSubject(event.target.value)}
                >
                    <option value="webdesign">Webdesign</option>
                    <option value="webdev">Développement web</option>
                    <option value="dont know yet">Je ne sais pas encore</option>
                </select>
                <div className="input-field message">
                    <textarea
                        value={message}
                        minLength="1"
                        required
                        onChange={(event) => setMessage(event.target.value)}
                    />
                    <label>Message</label>
                </div>
                <Button type="submit">Envoyer</Button>
            </form>
        </div>
    );
}
