import React from 'react';

import './Button.scss';

export default function Button({ children, onClick }) {
    return (
        <button className="button" onClick={onClick}>
            <span>{children}</span>
        </button>
    );
}